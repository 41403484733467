'use client';
import Image from 'next/image';
import { useEffect } from 'react';

export function Ratings() {
  useEffect(() => {
    setTimeout(function () {
      var id = 'dda54e01';
      var script = document.getElementById('pre' + id);
      if (!document.getElementById(id) && script) {
        var loader = document.createElement('script');
        loader.id = id;
        loader.src = '//widgets.reviews.rhino-reviews.com/assets/w.unmin.js';
        script?.parentNode?.insertBefore(loader, script);
      }
    }, 0);
  }, []);
  return (
    <div className="container fp-gray mx-auto mb-12 flex flex-col items-center">
      <div className="flex flex-col items-center">
        <img
          src="https://cdn.builder.io/api/v1/image/assets%2F25b27bb6bc614c2d9723f754b73aae70%2F82c76457fc5c497094690cad56db127c?format=webp"
          alt="Frontpoint Icon"
        />
        <h2>What our clients say</h2>
      </div>
      <div></div>
      <script id="predda54e01"></script>

      <div className="mt-4 flex w-full flex-col items-center justify-center">
        <h5 className="text-center text-2xl lg:text-nowrap">
          Rating based on combined reviews from Google and Trustpilot.
        </h5>
        <div className="mt-4 flex w-full flex-row items-center justify-center gap-10">
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Frontpoint+Security/@38.989791,-77.4430551,17z/data=!3m1!4b1!4m6!3m5!1s0x89b64b535ef67d3b:0xf456778526e00ba8!8m2!3d38.989791!4d-77.4430551!16s%2Fg%2F11g1_y27vl?entry=ttu&g_ep=EgoyMDI1MDEyMS4wIKXMDSoASAFQAw%3D%3D"
          >
            <Image
              src="https://cdn.shopify.com/s/files/1/0626/1646/9558/files/logo_google.webp?v=1728026849"
              alt="Rhino Reviews"
              width={100}
              height={100}
            />
          </a>
          <a target="_blank" href="https://www.trustpilot.com/review/www.frontpointsecurity.com">
            <Image
              src="https://cdn.shopify.com/s/files/1/0626/1646/9558/files/trustpilot_logo.webp?v=1728026849"
              alt="Rhino Reviews"
              width={250}
              height={10}
            />
          </a>          
        </div>
      </div>
    </div>
  );
}
